/**
 * options:
 * options.type // 错误类型
 * options.message //错误提示信息
 * options.statusCode // HttpCode
 * options.workCode // 业务状态码
 */
export default class HttpError {
    constructor(options) {
        HttpError.type = 'HTTP_ERROR'
        this.message = options.message || '接口出错'
        this.statusCode = options.status || 0
    }
    getType() {
        return HttpError.type
    }
}
