import * as api from './index.js'

/**
 * 添加/编辑申报单信息
 * @param {*} params
 * @param {number} params.id						新增则不传，编辑则传
 * @param {number} params.type						申请类型 1 护卫犬 2 工作犬
 * @param {string} params.name						公司名称
 * @param {string} params.address					公司地址
 * @param {string} params.licenseNo					营业执照号码
 * @param {string} params.licenseNoImg				营业执照照片
 * @param {string} params.licenseNoValidityTime		营业执照有效期
 * @param {string} params.legalPersonName			法人姓名
 * @param {string} params.legalPersonPhone			法人联系方式
 * @param {string} params.legalPersonIdnumber		法人身份证
 * @param {string} params.legalPersonIdnumberImg1	身份证正面照
 * @param {string} params.legalPersonIdnumberImg2	身份证反面照
 * @param {string} params.managerName				看护人姓名
 * @param {string} params.managerPhone				看护人联系方式
 * @param {string} params.managerIdnumber			看护人身份证号码
 * @param {string} params.managerIdnumberImg1		看护人身份证正面照
 * @param {string} params.managerIdnumberImg2		看护人身份证反面照
 * @param {string} params.purposeImg				养犬用途说明
 * @param {string} params.manageRulesImg			养犬单位管理制度
 * @param {string} params.feedingPlaceImg			饲养场所照片
 * @param {string} params.kennelImg					犬舍照片
 * @param {string} params.manageRulesImg2			单位上墙制度照片
 * @param {string} params.otherInfoImg1				其他资料1
 * @param {string} params.otherInfoImg2				其他资料2
 * @param {string} params.otherInfoImg3				其他资料3
 * @param {string} params.otherInfoImg4				其他资料4
 * @param {string} params.otherInfoImg5				其他资料5
 * @param {string} params.areaCode					犬类所在区域
 * @param {string} params.branchCode				所属单位
 * @returns
 */
export function editUnitDeclareInfo(params) {
    return api.POST('/declare/editUnitDeclareInfo', params)
}

/**
 * 查询报单详情
 * @param {*} params
 * @param {number} params.id
 */
export function queryUnitDeclareDetails(params) {
    return api.POST('/declare/queryUnitDeclareDetails', params)
}

/**
 * 提交申报单
 * @param {*} params
 * @param {number} params.id
 */
export function submitUnitDeclare(params) {
    return api.POST('/declare/submitUnitDeclare', params)
}

/**
 * 查询申报单列表
 * @param {*} params
 * @param {number} params.id
 */
export function queryUnitDeclareList(params) {
    return api.POST('/declare/queryUnitDeclareList', params)
}

/**
 * 查询申报单列表
 * @param {*} params
 * @param {number} params.id
 */
export function withdrawUnitDeclare(params) {
    return api.POST('/declare/withdrawUnitDeclare', params)
}

/**
 * 查询单位
 * @param {*} params
 * @param {number} params.id
 */
export function findAllBranch(params) {
    return api.POST('/declare/findAllBranch', params)
}

/**
 * 查询单位
 * @param {*} params
 * @param {number} params.id
 */
export function findAllArea(params) {
    return api.POST('/declare/findAllArea', params)
}

/** 上传犬只信息
 * @param {*} params
 * @param {number} params.id
 * @param {array} params.doginfos
 * @param {string} params.doginfos.name
 * @param {number} params.doginfos.gender
 * @param {string} params.doginfos.breed
 * @param {string} params.doginfos.coatColor
 * @param {string} params.doginfos.birthday
 * @param {string} params.doginfos.petPhotoUrl
 */
export function uploadUnitDeclareDogsInfo(params) {
    return api.POST('/declare/uploadUnitDeclareDogsInfo', params)
}

