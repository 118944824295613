
import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '../api/apply.js'
import { uploadFiles } from '../api/index.js'
import WorkError from '../util/WorkError.js'

Vue.use(Vuex)

// 获取区域的code,name
export function getSimpleArea(areaTree, arr = []) {
    const { code, name, children } = areaTree
    const _item = {
        code,
        name
    }
    arr.push(_item)
    if (Array.isArray(children) && children.length > 0) {
        children.forEach(item => {
            getSimpleArea(item, arr)
        })
    }
}
const store = new Vuex.Store({
    state: {
        declareId: '',
        applyData: {},
        areaList: [],
        petType: [
            { label: '工作犬', code: 1 },
            { label: '护卫犬', code: 2 },
            { label: '商品犬', code: 4 }
        ],
        petGender: [
            { label: '雄', code: 1 },
            { label: '雌', code: 2 }
        ]
    },
    mutations: {
        SET_DECLARE_ID(state, payload) {
            state.declareId = Number(payload)
        },
        SET_APPLY_DATA(state, payload) {
            state.applyData = payload
        },
        SET_AREA_LIST(state, payload) {
            state.areaList = payload
        }
    },
    getters: {
        getApplyData(state) {
            return state.applyData
        },
        getDeclareId(state) {
            return state.declareId
        },
        getPetType(state) {
            return state.petType
        },
        getAreaList(state) {
            return state.areaList
        },
        getPetGender(state) {
            return state.petGender
        }
    },
    actions: {
        async queryUnitDeclareDetails({ state, dispatch, commit }, payload) {
            try {
                const res = await api.queryUnitDeclareDetails(payload)
                if (res.respCode === '0000') {
                    commit('SET_APPLY_DATA', res.respData)
                    commit('SET_DECLARE_ID', res.respData.id)
                    return res.respData
                } else {
                    throw new WorkError({
                        workCode: res.respCode,
                        message: res.respMsg
                    })
                }
            } catch (error) {
                return Promise.reject(error)
            }
        },
        async editUnitDeclareInfo({ state, dispatch, commit }, payload) {
            try {
                const res = await api.editUnitDeclareInfo(payload)
                if (res.respCode === '0000') {
                    await dispatch('queryUnitDeclareDetails', { id: res.respData.id })
                    commit('SET_DECLARE_ID', res.respData.id)
                } else {
                    throw new WorkError({
                        workCode: res.respCode,
                        message: res.respMsg
                    })
                }
            } catch (error) {
                return Promise.reject(error)
            }
        },
        async uploadUnitDeclareDogsInfo({ state, dispatch, commit }, payload) {
            try {
                const res = await api.uploadUnitDeclareDogsInfo(payload)
                if (res.respCode === '0000') {
                    return res
                } else {
                    throw new WorkError({
                        workCode: res.respCode,
                        message: res.respMsg
                    })
                }
            } catch (error) {
                return Promise.reject(error)
            }
        },
        async submitUnitDeclare(context, payload) {
            try {
                const res = await api.submitUnitDeclare(payload)
                if (res.respCode === '0000') {
                    return res
                } else {
                    throw new WorkError({
                        workCode: res.respCode,
                        message: res.respMsg
                    })
                }
            } catch (error) {
                return Promise.reject(error)
            }
        },
        async uploadImageFile(context, payload) {
            try {
                const res = await uploadFiles(payload)
                if (res.respCode === '0000') {
                    return res.respData
                } else {
                    throw new WorkError({
                        workCode: res.respCode,
                        message: res.respMsg
                    })
                }
            } catch (error) {
                return Promise.reject(error)
            }
        },
        async findAllArea({ commit }, payload) {
            try {
                const res = await api.findAllArea(payload)
                if (res.respCode === '0000') {
                    const simpleAreaList = []
                    getSimpleArea(res.respData.rootArea, simpleAreaList)
                    commit('SET_AREA_LIST', simpleAreaList)
                } else {
                    throw new WorkError({
                        workCode: res.respCode,
                        message: res.respMsg
                    })
                }
            } catch (error) {
                if (error.getType() === 'WORK_ERROR') {
                    this.$message.error(error.message)
                }
            }
        }
    }
})

export default store
