import Vue from "vue"
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from "./App.vue"
import router from "./router"
import store from "./store"

Vue.config.productionTip = false
Vue.use(ElementUI)

// 事件bus
Vue.prototype.$eventBus = new Vue()

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount("#app")

