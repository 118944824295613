<template>
	<el-container>
		<el-header class="index-header">
			<div class="placeholder"></div>
			<div class="index-header__container">
				<div class="logo-content" @click="goHome()">
					<div class="logo">
						<img src="../assets/img/logo.png" alt="">
					</div>
					<div class="text-content">
						<div class="text-t">廊坊市公安局犬类管理</div>
						<div class="text-b">单位养犬申办服务平台</div>
					</div>
				</div>
				<div class="user-content" v-if="loginPhone.length > 0">
					<el-dropdown @command="handleCommand">
						<span class="el-dropdown-link">
							<span>用户：{{loginPhone}}</span>
							<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="apply">申办犬只</el-dropdown-item>
							<el-dropdown-item command="apply-list">历史申办</el-dropdown-item>
							<el-dropdown-item command="login">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
		</el-header>
		<el-main class="index-container">
			<transition name="move" mode="out-in">
				<router-view></router-view>
			</transition>
		</el-main>
	</el-container>
</template>
<script>
import db from '../util/localStorage.js'
export default {
    data() {
        return {
            loginPhone: ''
        }
    },
    created() {
        this.getUserInfo()
    },
    beforeUpdate() {
        this.getUserInfo()
    },
    methods: {
        getUserInfo() {
            const loginPhone = db.get('__apply_phone__')
            this.loginPhone = loginPhone
        },
        handleCommand(command) {
            if (command === 'apply') {
                this.$router.push({
                    path: '/apply'
                })
                setTimeout(() => {
                    this.$router.go(0)
                }, 20)
                return
            }
            if (command === 'login') {
                db.remove('__apply_phone__')
                db.remove('__apply_authorization__')
            }
            this.$router.push({
                path: `/${command}`
            })
        },
        goHome() {
            this.$router.push({
                path: '/index'
            })
        }
    }
}
</script>
<style lang="scss">
	.index-header {
		width: 100%;
		height: 120px !important;
		background-color: #f5f5f5;
		padding: 0;
		.index-header__container {
			width: 1100px;
			height: 100%;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding-top: 20px;
		}
		.logo-content {
			display: flex;
			flex-direction: row;
			align-items: center;
			cursor: pointer;
			.logo {
				width: 55px;
				height: 55px;
				border-radius: 50%;
				overflow: hidden;
				img {
					width: 100%;
				}
			}
			.text-content {
				display: flex;
				flex-direction: column;
				justify-content: center;
				margin-left: 8px;
				.text-t {
					font-size: 16px;
					text-align: justify;
				}
				.text-b {
					font-size: 25px;
					font-weight: 800;
				}
			}
		}
		.user-content {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-direction: row;
			cursor: pointer;
		}
	}
	.index-container {
		padding: 30px 0;
		background-color: #e6ecf2;
	}
</style>

