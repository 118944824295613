import Vue from "vue"
import VueRouter from "vue-router"
import db from "@/util/localStorage.js"
import NotFound from '../components/404.vue'
import Layout from '../components/Layout.vue'

Vue.use(VueRouter)

// 解决elementui导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [{
    path: '/',
    redirect: '/index',
    component: Layout,
    children: [{
        path: 'index',
        component: () => import(/* webpackChunkName: "Index" */ '../views/Index.vue')
    }, {
        path: 'login',
        component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
    }, {
        name: 'apply',
        path: 'apply/:id?',
        component: () => import(/* webpackChunkName: "Login" */ '../views/Apply.vue')
    }, {
        path: 'apply-list',
        component: () => import(/* webpackChunkName: "Login" */ '../views/ApplyList.vue')
    }, {
        path: 'apply-detail/:id',
        name: 'apply-detail',
        component: () => import(/* webpackChunkName: "Login" */ '../views/ApplyDetail.vue')
    }]
}, {
    path: "/404",
    component: NotFound
}, {
    path: '*',
    redirect: '/404'
}]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})

// 设置白名单
let whiteList = routes.map(route => route.path)
whiteList = [...whiteList, '/login']

// 使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
    const authorizationStr = db.get('__apply_authorization__', '')
    if (authorizationStr && authorizationStr.length > 0) {
        // 已登录且要跳转的页面是登录页
        if (to.path === '/login') {
            next({ path: '/' })
            return
        }
        next()
    } else {
        // 在免登录白名单，直接进入
        if (whiteList.indexOf(to.path) > -1) {
            next()
            return
        }
        // 是否需要清空路由表？
        next({ path: '/login', replace: true })
    }
})

export default router
