import Vue from 'vue'
import axios from 'axios'
import router from '../router/index'
import db from '@/util/localStorage.js'
import HttpError from '@/util/HttpError'

const baseURL = process.env.VUE_APP_URL
const fileUploadURL = process.env.VUE_APP_FILE_URL

axios.interceptors.request.use(
    (config) => {
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        const e = {}
        const message = Vue.prototype.$message

        if (error.response) {
            const status = error.response.status
            e.message = error.response.data.respMsg
            e.workCode = error.response.data.respCode
            e.statusCode = status
            switch (status) {
                case 400:
                    message('参数错误')
                    break
                case 401:
                case 403:
                    message('用户信息失效,重新登录！')
                    db.remove('__apply_authorization__')
                    db.remove('__apply_phone__')
                    router.replace({ path: '/login' })
                    break
                case 404:
                    message('请求地址找不到！')
                    break
                case 422:
                    message('请求参数错误！')
                    break
                case 500:
                    message('服务开小差了！')
                    break
                case 502:
                case 503:
                case 504:
                    message('网络请求超时！')
                    break
                default:
                    break
            }
        } else {
            e.statusCode = 504
            e.workCode = '9999'
            e.message = '请求超时'
            message('网络似乎出了点问题')
        }
        return Promise.reject(new HttpError(e))
    }
)
// headers: {}, 添加自定义请求头,
// encode: params是否需要编码
function ajax(
    method = 'GET',
    url = '',
    params = {},
    options = {
        headers: {},
        encode: false
    }
) {
    const config = {
        baseURL,
        url,
        method,
        timeout: 8000,
        headers: {}
    }

    // 修改baseUrl地址，区分文件上传地址和普通接口地址
    const fileUploadPath = ['/uploadFiles']
    if (fileUploadPath.indexOf(url) > -1) {
        config.baseURL = fileUploadURL
    }
    // 请求头中添加token
    const token = db.get('__apply_authorization__', '')
    if (token.length > 0) {
        config.headers['authorization'] = `Bearer ${token}`
    }
    // 合并请求头
    if (Object.keys(options.headers).length > 0) {
        for (const key in options.headers) {
            config.headers[key] = options.headers[key]
        }
    }
    // 对参数进行编码
    if (method === 'GET' && options.encode) {
        if (Object.keys(params).length > 0) {
            for (const key in params) {
                params[key] = encodeURIComponent(params[key])
            }
        }
    }
    // 请求方式data传参数
    if (['PUT', 'POST', 'PATCH'].includes(method)) config.data = params
    else config.params = params

    // 网络请求
    return new Promise((resolve, reject) => {
        axios.request(config)
            .then((response) => resolve(response.data))
            .catch((error) => {
                console.log(error)
                reject(error)
            })
    })
}

// 对外暴露请求方法
export const POST = (url, params) => {
    return ajax('POST', url, params)
}

export const GET = (url, params) => {
    return ajax('GET', url, params)
}

export const PUT = (url, params) => {
    return ajax('PUT', url, params)
}

export const DELETE = (url, params) => {
    return ajax('DELETE', url, params)
}

export const PATCH = (url, params) => {
    return ajax('PATCH', url, params)
}

/**
 * 上传文件
 * chlNo: 8000,
 * chlFile: xxxx
 */
export function uploadFiles(params) {
    const headers = { 'content-type': 'multipart/form-data' }
    return ajax('POST', '/uploadFiles', params, { headers })
}

export default ajax
