const db = {
    save(key, value) {
        localStorage.setItem(key, JSON.stringify(value))
    },
    get(key, defaultValue = {}) {
        let value = localStorage.getItem(key)
        if (this.isUndef(value)) {
            value = defaultValue
            this.remove(key)
        }
        if (value.length > 0) {
            return JSON.parse(value)
        }
        return value
    },
    remove(key) {
        localStorage.removeItem(key)
    },
    clear() {
        localStorage.clear()
    },
    isUndef(v) {
        const reg = new RegExp("undefined")
        return reg.test(v) || v === undefined || v === null
    }
}

export default db
