/**
 * options:
 * options.type // 错误类型
 * options.message //错误提示信息
 * options.workCode // workCode
 */
export default class WorkError extends Error {
    constructor(options) {
        super(options.message || '业务出错')
        WorkError.type = 'WORK_ERROR'
        this.message = options.message || '业务出错'
        this.statusCode = options.workCode || '9999'
    }
    mergeOtherOptions(options) {
        const arr = Object.keys(options)
        if (arr.length <= 2) return
        for (const key in options) {
            if (key === 'message' || key === 'workCode') continue
            else this[key] = options[key]
        }
    }
    addOption(key, value) {
        this[key] = value

        return this
    }
    getType() {
        return WorkError.type
    }
}
